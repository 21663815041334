import { AppBar, Button, Checkbox, Chip, Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Toolbar, Typography } from '@material-ui/core';
import { PhotoGallery, Section, CustomButton3 } from '@sites/shared-ui';
import React, { useRef, useLayoutEffect } from 'react'
import GsapSlider from '@sites/shared-ui/components/BannerSlider/GsapSlider';
import Layout from './index'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { StaticImage } from 'gatsby-plugin-image'
import GetGraphQlImage from '../helpers/GetGraphQlImage'
import { DoubleArrow, Videocam, DirectionsRun, House, Power, EmojiPeople, FitnessCenter, LocalDrink, NaturePeople, SportsEsports, AmpStories, DriveEta, Kitchen, Deck, Whatshot  } from '@material-ui/icons';
import Img from 'gatsby-image'
import { isblank } from '@sites/shared-ui/util/commonfunctions';
import gsap from 'gsap'
import SEO from '../components/seo'

const drawerWidth = 650;

const useStyles = makeStyles((theme) => ({
  bg__img: {
    position: 'absolute',
    zIndex: 0,
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    objectFit: 'cover'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    }
  },
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      display: 'none',
      width: 0
    }
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1
  },
  sectionHeader: {
     marginBottom: 32
  },
  divider: {
		borderBottom: '1px solid #fff',
		backgroundColor: '#AEC702',
		height: 1,
		marginTop: 16,
    marginBottom: 16
  },
  dividerSpan: {
      display: 'block',
      backgroundColor: '#AEC702',
      width: 50,
      height: 1
  },
  whyBox: {
    padding: 32,
    backgroundColor: '#AEC702',
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 32,
      paddingBottom: 32
    }
  }
}))

const stringToIcon = {
    "Videocam" : <Videocam />,
    "DirectionsRun" : <DirectionsRun />, 
    "House": <House />, 
    "Power": <Power />,
    "EmojiPeople": <EmojiPeople/>, 
    "FitnessCenter": <FitnessCenter />,
    "LocalDrink": <LocalDrink />,
    "NaturePeople": <NaturePeople />,
    "SportsEsports": <SportsEsports />,
    "AmpStories": <AmpStories />,
    "DriveEta": <DriveEta />,
    "Kitchen": <Kitchen />,
    "Deck": <Deck />,
    "Whatshot": <Whatshot />
}

function scrollToDiv(divID) {
  gsap.to(window, { duration: 1, scrollTo:{ y: `${divID}`, offsetY: 44 } });
}

export default ({ pageContext, data }) => {
  //console.log(pageContext);
  const classes = useStyles();

  var project = pageContext.node;

  const lightboxOptions = {
    imageLoadErrorMessage: 'Cannot load Images',
    nextLabel: 'Next',
    prevLabel: 'Prev',
    zoomInLabel: 'Zoom',
    zoomOutLabel: 'DeZoom',
    closeLabel: 'Close',
  }

  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.thumb.originalName,
  })).filter((image) => image.caption.includes(project.gallery));

  const text = {
    fontSize: 20
  };

  return (
    <Layout footer='false'>
    <SEO title={project.seo_title} description={project.seo_title} />
    <div className={classes.root}>
      <main className={classes.content}>
        <div style={{ paddingBottom: 50 }}>
        <Section id="project-desc" narrow narrowPadding>
          <div className={classes.sectionHeader}>
            { !isblank(project.title) &&
            <Typography variant='h3'>{project.title}</Typography> }
            { !isblank(project.sub_title) &&
            <Typography variant='h5'>{project.sub_title}</Typography> }
            { !isblank(project.location) &&
            <Typography variant='subtitle1'>{project.location}</Typography> }
            { !isblank(project.status) &&
            <Chip size="small" style={{ marginTop: 8 }} label={project.status} />
            }
            <div className={classes.divider}><div  className={classes.dividerSpan}></div></div>
          </div>

          { !isblank(project.description) &&
            <div>
            {
            project.description.map(item => {
              return (
                <>
                <Typography variant='h5' gutterBottom>{item.title}</Typography>
                <Typography variant='subtitle1' gutterBottom>{item.description}</Typography>
                </>
              )
            })
            }
            </div>
          }
        </Section>

        { !isblank(project.specification) &&
        <Section id="project-specifications" narrow narrowPadding>
          <div className={classes.sectionHeader}>
            <Typography variant='h3'>Specifications</Typography>
            <Typography variant='subtitle1'>About Specifications</Typography>
            <div className={classes.divider}><div  className={classes.dividerSpan}></div></div>
          </div>
          <Grid container spacing={3}>
          {
            project.specification.map(item => {
              return (
                <Grid item xs={12} md={6}>
                  <Paper style={{ padding: 8}}>
                    <Typography variant='h6' gutterBottom>{item.title}</Typography>
                    <Typography variant='subtitle1'>{item.description}</Typography>
                    {/* <GetGraphQlImage
                      imageName="lotus_heights_isanpur_7.jpeg"
                      imageAltName="About Lotus Heights"
                      style={{ width: 150 }}
                    /> */}
                  </Paper>
                </Grid>
              )
            })
          }
          </Grid>
        </Section>
        }

        { !isblank(project.amenities) &&
        <Section id="project-amenities" narrow narrowPadding>
          <div className={classes.sectionHeader}>
            <Typography variant='h3'>Amenities</Typography>
            <Typography variant='subtitle1'>About Amenities</Typography>
            <div className={classes.divider}><div  className={classes.dividerSpan}></div></div>
          </div>
          <Grid container spacing={3}>
          {
            project.amenities.map(item => {
              return (<Grid item xs={12} md={4}>
                    <Paper style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
                      {stringToIcon[`${item.icon}`]}
                      <Typography style={{ marginLeft: 8 }} variant='subtitle1'>{item.title}</Typography>
                    </Paper>
                  </Grid>)
            })
          }
           </Grid>
        </Section> 
        }

        <Section id="project-gallery" narrow narrowPadding>
          <div className={classes.sectionHeader}>
            <Typography variant='h3'>Gallery</Typography>
            <Typography variant='subtitle1'>Project Gallery/Views/Layout Plans</Typography>
            <div className={classes.divider}><div  className={classes.dividerSpan}></div></div>
          </div>
          <PhotoGallery images={images} lightboxOptions={lightboxOptions} />
        </Section>

        <Section id="project-why-section" narrow narrowPadding>
            <Paper className={ classes.whyBox }>
                <Typography variant='h4' gutterBottom>Why you should consider {project.title}</Typography>

                { !isblank(project.why_section) &&
                  <div style={{ marginBottom: 16 }}>
                  <List dense>
                  {
                    project.why_section.map(item => {
                      return (
                        <ListItem>
                          <ListItemIcon>
                            <DoubleArrow />
                          </ListItemIcon>
                            <ListItemText
                              disableTypography
                              primary={<Typography variant='h6' style={{ color: '#FFFFFF' }}>{item}</Typography>}
                            />
                            {/* <ListItemText
                              primary={item}
                              primaryTypographyProps={{ style: text }}
                            /> */}
                        </ListItem>
                      )
                    })
                  }
                  </List>
                  </div>
              }
                { !isblank(project.brochure) &&
                  <div style={{ textAlign: 'center' }}>
                    <CustomButton3 target="_blank" rel="noopener norefferer" href={project.brochure}>Download Brochure</CustomButton3>
                  </div>
                }
            </Paper>
        </Section>

        <Section id="project-contact" narrow narrowPadding>
          <div className={classes.sectionHeader}>
            <Typography variant='h3'>Location</Typography>
            <Typography variant='subtitle1'>Project Contact</Typography>
            <div className={classes.divider}><div  className={classes.dividerSpan}></div></div>
          </div>
         
          { !isblank(project.contact.map_location) &&
            <div style={{ marginBottom: 16 }}>
              <iframe
                src={project.contact.map_location}
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </div>
          }

          <Typography variant='h3' gutterBottom>Contact</Typography>
          { !isblank(project.contact.address) &&
          <Typography variant='subtitle1' gutterBottom>{project.contact.address}</Typography> }
          { !isblank(project.contact.address2) &&
          <Typography variant='subtitle1' gutterBottom>{project.contact.address2}</Typography> }
          { !isblank(project.contact.phone) &&
          <Typography variant='subtitle1' gutterBottom>{project.contact.phone}</Typography> }
          { !isblank(project.contact.mail) &&
          <Typography variant='subtitle1'>{project.contact.mail}</Typography> }
        
        </Section>
        </div>
        <AppBar position="sticky" color="default" className={classes.appBar}>
          <Toolbar style={{ justifyContent: 'center' }}>
              <Button onClick={ () => scrollToDiv('#project-desc')} color="inherit">{project.title}</Button>
            { !isblank(project.specification) &&
              <Button onClick={ () => scrollToDiv('#project-specifications')} color="inherit">Specifications</Button> }
            { !isblank(project.amenities) &&
              <Button onClick={ () => scrollToDiv('#project-amenities')} color="inherit">Amenities</Button>
            }
            <Button onClick={ () => scrollToDiv('#project-gallery')} color="inherit">Gallery</Button>
            <Button onClick={ () => scrollToDiv('#project-why-section')} color="inherit">Why Section</Button>
            <Button onClick={ () => scrollToDiv('#project-contact')} color="inherit">Contact</Button>
          </Toolbar>
        </AppBar>
      </main>
      <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right">
          <GsapSlider>
            {images.slice(0,3).map((image, imageIndex) => {
            return (
                <Img
                  key={imageIndex}
                  className={classes.bg__img}
                  fluid={image.full}
                  alt={project.title}
                />
                )
              }
            )}
          </GsapSlider>

          <Paper style={{ padding: 16 }}>
            <Typography variant='h3'>{project.title}</Typography>
            <Typography variant='h5'>{project.sub_title}</Typography>
            <Typography variant='subtitle1' gutterBottom>{project.location}</Typography>
            <CustomButton3>Inquire Now</CustomButton3>
          </Paper>
      </Drawer>
    </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 512, maxHeight: 512) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  },
  
`
