import { makeStyles } from '@material-ui/core/styles'
import gsap from 'gsap'
import React, { useRef, useLayoutEffect } from 'react'


const useStyles = makeStyles((theme) => ({
  home__bg: {
    display: 'block',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
  },

  imgContainerli: {
    position:'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    listStyle: 'none',
    visibility: 'hidden',
    '&:first-child': {
      visibility: 'visible'
    }
  },

  dotContainer: {
    position: 'absolute',
    display: 'none',
    top: 250,
    left: 38
  },

  dot: {
    position: 'relative',
    width: 30,
    height: 30,
    background: 'red',
    marginRight:5,
    float:'left'
  }
}))

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const GsapSlider = props => {
  const { children, ...rest } = props
  const classes = useStyles()
  
  const slider_ref = useRef()
  const dot_ref = useRef()

  useLayoutEffect(() => {
     let slider = slider_ref.current;
    let dot = dot_ref.current;
    
    var imgs = slider.children;
    imgs = Array.from(imgs)
    
    var dots = dot.children;
    dots = Array.from(dots)
    gsap.set(dots[0], {backgroundColor:'green'})
    
    var next = 3; // time to change
    
    function crossfade(){
      
      var action = gsap.timeline();
      action.to(imgs[0], {duration : 1, autoAlpha:0})
      action.to(imgs[1], {duration : 1,autoAlpha:1, onStart:doOtherstuff},0)
      action.to(dots[0], {duration : 0.1,backgroundColor:'red'},0)
      action.to(dots[1], {duration : 0.1,backgroundColor:'green'},0)
      
      //remove the first img (shift) 
      //and add (push) it to the end of the imgs array
      //and the same for the dots array

      imgs.push( imgs.shift() );
      dots.push( dots.shift() );
      
      // start endless run
      gsap.delayedCall(next, crossfade);
    }
    
    // start the crossfade after next = 3 sec
    gsap.delayedCall(next, crossfade);
  },[]);

  function doOtherstuff(){
    //console.log('Year, but what ...')
  }

  return (
    <div className={ classes.home__bg }>
          <div ref={slider_ref} id="imgContainer">
            {children.map((childitem,index) => <li key={`slider${index}`} className={`${classes.imgContainerli}`}>{childitem}</li>)}
          </div>
          <div ref={dot_ref} className={classes.dotContainer}>
            {children.map((childitem,index) =><div key={`dot${index}`} className={classes.dot}></div>)}
          </div>
    </div>
  )
}
export default GsapSlider
