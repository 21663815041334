import { makeStyles } from '@material-ui/core/styles'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const GetGraphQlImage = ({ imageName, imageAltName, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allImageSharp {
            edges {
              node {
                gatsbyImageData(placeholder: BLURRED)
                sizes {
                  originalName
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {data.allImageSharp.edges.map((image, index) => {
            const imageUrl = getImage(image.node)
            const image_Name = image.node.sizes.originalName
            if (image_Name === imageName) {
              return (
                <GatsbyImage {...rest} image={imageUrl} alt={imageAltName} />
              )
            }
          })}
        </>
      )}
    />
  )
}

export default GetGraphQlImage
